import axios from "axios";
import { createHubspotContact } from ".";
import { apiUrl } from "./constants";

export const axiosAPI = axios.create({
  baseURL: apiUrl,
});

// export const axiosHubspot = axios.create({
//   baseURL: apiUrl,
// });

export const AddUserToContact = async (access_token) => {
  try {
    const response = await axios.get(
      "https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses,phoneNumbers",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    const userData = response.data;
    if (userData.emailAddresses) {
      const emailValue = userData.emailAddresses[0].value;
      const firstName = userData.names
        ? userData.names[0]?.givenName || "-"
        : "-";
      const lastName = userData.names
        ? userData.names[0]?.familyName || "-"
        : "-";
      const phoneNumber = userData.phoneNumbers
        ? userData.phoneNumbers[0].canonicalForm || "-"
        : "-";
      let userContact = {
        email: emailValue,
        name: `${firstName}  ${lastName}`,
        phoneNumber: phoneNumber,
      };

      createHubspotContact(userContact);
    }
  } catch (e) {
    // console.log(e);
  }
};

export const postAppScript = async (scriptPath, reqBody, access_token) => {
  return axios.post(scriptPath, reqBody, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};
