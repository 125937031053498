import ArrowRightIcon from "@heroicons/react/outline/ArrowRightIcon";
import { useState } from "react";
import { axiosAPI } from "../utils/services";
import { createHubspotContact } from "../utils";
import { BeatLoader } from "react-spinners";
const InputWithLabel = ({ name, type, ...props }) => (
  <div className="flex flex-col space-y-2">
    <label className="text-base font-medium text-slate-body"> {name} </label>
    <input className="p-3 border-2 rounded-lg" type={type} {...props} />
  </div>
);

const TextAreaWithLabel = ({ name, ...props }) => (
  <div className="flex flex-col space-y-2">
    <label className="text-base font-medium text-slate-body"> {name} </label>
    <textarea className="p-3 border-2 rounded-lg" {...props} rows={6} />
  </div>
);

const ContinueButton = ({ onClick, label, loading }) => (
  <button
    disabled={loading}
    className={`flex items-center justify-center w-full px-6 py-4 space-x-2 rounded-lg transition-all duration-300 border-brand-pink border-2 text-brand-pink  ${
      loading ? "bg-brand-pink" : ""
    } hover:bg-brand-pink hover:text-white`}
    onClick={onClick}
  >
    {loading ? (
      <BeatLoader size={15} color="white" />
    ) : (
      <span className="text-lg font-medium "> {label} </span>
    )}
    {!loading && <ArrowRightIcon className="w-5" />}
  </button>
);

const Register5 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const sendContact = async () => {
    try {
      setMessage(null);
      if (!formData.name || !formData.email) {
        return setMessage({
          status: false,
          message: "Please fill all fields",
        });
      }
      setIsLoading(true);

      await axiosAPI
        .post(`/api/contactUs`, formData)
        .then(async () => {
          setMessage({
            status: true,
            message: "Successful",
          });

          setFormData({
            name: "",
            email: "",
            message: "",
          });
        })
        .catch((e) => {
          setMessage({
            status: false,
            message: "Oops! Something went wrong. Please try again later.",
          });
        })
        .finally(async () => {
          setIsLoading(false);
          setTimeout(() => {
            setMessage({
              status: true,
              message: "",
            });
          }, 3000);
        });
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="min-h-screen bg-white font-dm-sans ">
      <div>
        <div className="min-h-screen mx-6 md:mx-0">
          {/* <div className="hidden bg-cover md:block md:w-1/3 bg-register-page">
            <Sidebar />
          </div> */}
          <div className="my-12 md:my-0 pt-[80px] md:pt-[120px] min-h-[80vh] ">
            <div className="cntc-blk flex items-center justify-center h-full">
              <div className="md:w-[574px] w-full space-y-8">
                <h1 className="font-medium text-[32px]"> Get In Touch </h1>
                <InputWithLabel
                  name={"Name"}
                  type={"text"}
                  placeholder="Micheal Martey"
                  required
                  value={formData?.name}
                  onChange={(e) => {
                    e.persist();
                    setFormData((data) => ({
                      ...data,
                      name: e.target.value,
                    }));
                  }}
                />
                {/* <InputWithLabel
                  name={"Phone Number"}
                  type={"number"}
                  placeholder="0244444444"
                  required
                  value={formData?.phoneNumber}
                  onChange={(e) => {
                    e.persist();
                    setFormData((data) => ({
                      ...data,
                      phoneNumber: e.target.value,
                    }));
                  }}
                /> */}
                <InputWithLabel
                  name={"Email"}
                  type={"email"}
                  placeholder="mikeMartey@gmail.com"
                  required
                  value={formData?.email}
                  onChange={(e) => {
                    e.persist();
                    setFormData((data) => ({
                      ...data,
                      email: e.target.value,
                    }));
                  }}
                />
                <TextAreaWithLabel
                  name={"Your Message"}
                  value={formData?.message}
                  onChange={(e) => {
                    e.persist();
                    setFormData((data) => ({
                      ...data,
                      message: e.target.value,
                    }));
                  }}
                />

                <p
                  className={`${
                    message?.status ? "text-green-500" : "text-red-500"
                  }  text-xs my-1`}
                >
                  {message?.message}
                </p>
                <ContinueButton
                  onClick={sendContact}
                  label={"Send"}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register5;
