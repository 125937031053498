export const homePageData = {
  id: 1,
  published_at: "2022-03-07T15:08:26.684Z",
  created_at: "2022-03-07T15:08:12.595Z",
  updated_at: "2023-02-13T21:19:51.880Z",
  feature: [
    {
      id: 1,
      name: "Analysing With PrepTime",
      description:
        '<p><span style="color: rgb(0, 0, 0);">Welcome to PrepTime Analytics!</span></p><p><span style="color: rgb(0, 0, 0);">PrepTime Analytics is a Google Forms and Sheets add-on that enhances their functionality to analyze and report the results of assessments in a meaningful way. Findings from the report serve as inputs for adjustments or improvements.</span></p>',
      media: [],
    },
    {
      id: 2,
      name: "Get Your Analysis Ready In Seconds!",
      description:
        '<ul><li>Open responses in Google Sheets</li><li>Install “PrepTime Analytics add-on”</li><li>Fill in all of your details ie. School Name</li><li>Click "Generate Analysis”</li></ul>',
      media: [],
    },
    {
      id: 3,
      name: "In Your Report",
      description:
        "<ul><li>Summary of results</li><li>Item analysis</li><li>Graph of correct responses</li><li>Frequently missed questions</li></ul>",
      media: [
        {
          id: 12,
          name: "sheet.png",
          alternativeText: "",
          caption: "",
          width: 1668,
          height: 922,
          formats: {
            large: {
              ext: ".png",
              url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2Flarge_sheet_24e7f8462f.png?alt=media&token=2fcd37d6-814a-46dc-a6a2-5bfcf88022c5",
              hash: "large_sheet_24e7f8462f",
              mime: "image/png",
              name: "large_sheet.png",
              path: null,
              size: 201.43,
              width: 1000,
              height: 553,
            },
            small: {
              ext: ".png",
              url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2Fsheet_24e7f8462f.png?alt=media&token=f16240d7-86b6-40e0-b4ad-15d6da71a958",
              hash: "small_sheet_24e7f8462f",
              mime: "image/png",
              name: "small_sheet.png",
              path: null,
              size: 67.05,
              width: 500,
              height: 276,
            },
            medium: {
              ext: ".png",
              url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2Fmedium_sheet_24e7f8462f.png?alt=media&token=e4c76a09-6c31-4e5c-b22f-2f7d1d5e8499",
              hash: "medium_sheet_24e7f8462f",
              mime: "image/png",
              name: "medium_sheet.png",
              path: null,
              size: 127.01,
              width: 750,
              height: 415,
            },
            thumbnail: {
              ext: ".png",
              url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2Fthumbnail_sheet_24e7f8462f.png?alt=media&token=2a209123-c1cf-4e1f-a882-943d5b79938e",
              hash: "thumbnail_sheet_24e7f8462f",
              mime: "image/png",
              name: "thumbnail_sheet.png",
              path: null,
              size: 22.53,
              width: 245,
              height: 135,
            },
          },
          hash: "sheet_24e7f8462f",
          ext: ".png",
          mime: "image/png",
          size: 420.31,
          url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2Fsheet_24e7f8462f.png?alt=media&token=f16240d7-86b6-40e0-b4ad-15d6da71a958",
          previewUrl: null,
          provider: "aws-s3",
          provider_metadata: null,
          created_at: "2022-05-24T12:22:58.511Z",
          updated_at: "2022-05-24T12:22:58.517Z",
        },
      ],
    },
    {
      id: 4,
      name: "Graph Of Correct Responses",
      description:
        '<p><span style="color: rgb(0, 0, 0);">It helps to visualize your data in a way that is easy to understand and makes it easy for you to identify trends.</span></p>',
      media: [
        {
          id: 14,
          name: "Graph.png",
          alternativeText: "",
          caption: "",
          width: 842,
          height: 480,
          formats: {
            small: {
              ext: ".png",
              url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2Fsmall_Graph_2555d2fc4c.png?alt=media&token=a217b8bb-f509-4d07-bf8f-980ffa02f9f7",
              hash: "small_Graph_2555d2fc4c",
              mime: "image/png",
              name: "small_Graph.png",
              path: null,
              size: 57.29,
              width: 500,
              height: 285,
            },
            medium: {
              ext: ".png",
              url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2Fmedium_Graph_2555d2fc4c.png?alt=media&token=457f0e7d-434e-4da8-b2c5-391921102948",
              hash: "medium_Graph_2555d2fc4c",
              mime: "image/png",
              name: "medium_Graph.png",
              path: null,
              size: 109.13,
              width: 750,
              height: 428,
            },
            thumbnail: {
              ext: ".png",
              url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2Fthumbnail_Graph_2555d2fc4c.png?alt=media&token=0dbebef5-bc7c-4e92-adbd-2007283aa57b",
              hash: "thumbnail_Graph_2555d2fc4c",
              mime: "image/png",
              name: "thumbnail_Graph.png",
              path: null,
              size: 19.78,
              width: 245,
              height: 140,
            },
          },
          hash: "Graph_2555d2fc4c",
          ext: ".png",
          mime: "image/png",
          size: 108.85,
          url: "https://firebasestorage.googleapis.com/v0/b/preptime-analytics.appspot.com/o/preptime-cms%2FGraph_2555d2fc4c.png?alt=media&token=057671b9-59c2-49be-ac17-c278878b5da6",
          previewUrl: null,
          provider: "aws-s3",
          provider_metadata: null,
          created_at: "2022-06-16T11:48:32.476Z",
          updated_at: "2022-06-16T11:48:32.490Z",
        },
      ],
    },
  ],
};
