import { axiosAPI } from "./services";

export const createHubspotContact = async (formData) => {
  const lastSpaceIndex = formData.name.lastIndexOf(" ");
  const firstName = formData.name.slice(0, lastSpaceIndex);
  const lastName = formData.name.slice(lastSpaceIndex + 1);

  let contact = {
    email: formData.email,
    firstname: firstName,
    lastname: lastName,
  };
  try {
    const response = await axiosAPI.post("/api/contact", contact);
    return response;
  } catch (e) {
    // console.log(e);
  }
};
