export const blogPageData = [
  {
    id: 2,
    title: "How to Make The Best of Test Results",
    content:
      "<p>Assessment is a way of getting student feedback. It helps educators to measure the extent to which students have learnt...</p>",
    isFeaturedPost: false,
    tags: null,
    created_at: "2022-05-24T14:42:44.447Z",
    updated_at: "2022-05-28T12:28:35.627Z",
    thumbnail: null,
    featuredImage: null,
    media: [],
    categories: [],
  },
];
